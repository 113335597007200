table.unverified-table tr.MuiTableRow-root, table.unverified-table thead .MuiTableRow-root.MuiTableRow-head {
    height: 3em;

    @media screen and (max-width: 40em) {
        td { display: block; width: auto; border-bottom: 0; }
        width: 95vw;
        height: auto;
        display: grid;
        padding: 0.2em 0;
        border-bottom: 1px solid gray;

        grid-template-columns: auto 80px 40px 80px;
        grid-template-rows: auto;
        grid-template-areas: 
            "email referrer marketing actions" 
            "expire referrer marketing actions";

        .email-cell { grid-area: email; width: fit-content; }
        .referrer-cell { grid-area: referrer; }
        .marketing-cell { grid-area: marketing; }
        .expire-cell { grid-area: expire; }
        .actions-cell { grid-area: actions; }
    }
}
