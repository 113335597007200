.channelConfigScreen {
	.MuiBox-root {
		padding-bottom: 0px;
	}
	.miscDivider {
		margin-top: 40px;
	}
	.miscTitle {
		text-transform: uppercase;
		text-align: left;
		padding-top: 40px;
		font-weight: 500;
		font-size: 16px;

		&--disabled {
			color: rgba(0, 0, 0, 0.6);
		  }
	}
	.configValue {
		font-weight: 500;
	}
	.resolutionBox {
		&.disabled {
			background-color: $disabledFillGray;
		}
		width: 100px;
		border-color: $borderGray;
		border-width: 1px;
		border-style: solid;
		border-radius: 13px;
		width: 491px;
		margin-bottom: 12px;

		.priceDetails {
			padding-left: 24px;
			padding-bottom: 6px;
		}
		.header {
			display: flex;
			align-items: center;
			position: relative;
			margin-top: 8px;
			margin-bottom: 4px;
			min-height: 38px;
			.MuiSwitch-root {
				left: -5px;
			}
			.resolutionName {
				font-size: 20px;
				color: black;
				font-weight: 400;

				&--disabled {
					color: rgba(0, 0, 0, 0.6);
				  }
			}
			.defaultResolution {
				margin-left: 24px;
			}
			.toggleableResolution {
				margin-left: 0px;
				position: relative;
				left: -7px;
			}
		}
		.priceDetailSection {
			margin-bottom: 16px;
			.priceDetailsSectionName {
				display: flex;
				align-items: center;
				font-size: 16px;
				font-weight: 600;
				margin-bottom: 4px;
				min-height: 24px;
			}
		}
		.priceType {
			font-size: 16px;
			font-weight: 300;
			.priceValue {
				font-weight: 600;
				.castIcon {
					width: 12px;
				}
			}
		}
	}
	.editButtonSmall {
		height: 32px;
		width: 32px;
		.editIcon {
			height: 20px;
			width: 20px;
		}
		&.hidden {
			display: none;
		}
	}

	.customisedConfigText {
		font-size: 14px;
		color: #00c09a;
		padding-left: 10px;
		font-weight: 400;
	}
}

.channelConfigModal {
	.MuiPaper-root {
		padding-top: 64px;
		padding-bottom: 64px;
		padding-left: 36px;
		padding-right: 36px;
		max-width: 700px;
	}

	.MuiDialogTitle-root {
		padding-left: 0px;
		padding-right: 0px;
		padding-bottom: 4px;
		padding-top: 0px;
	}

	.MuiDialogContent-root {
		padding-left: 0px;
		padding-right: 0px;

		.MuiInputBase-root {
			min-width: 532px;
		}
	}

	.MuiDialogActions-root {
		padding-left: 0px;
		padding-right: 0px;

		.MuiButton-contained.Mui-disabled {
			background-color: white;
		}
	}
	.horizontalAlign {
		min-width: 532px;
		display: flex;
		align-items: center;
	}

	.helpText {
		color: $grayText;
		font-weight: 400;
		margin-bottom: 12px;
		font-size: 14px;
	}

	.radioLabel {
		font-size: 12;
		color: #1a1a1a;
		padding: 10px 0;
	}

	.fanDataSwitch {
		padding-left: 10px;
	}

	.formField {
		margin-top: 25px;
	}
	.additionalFormText {
		font-size: 14px;
		font-weight: 400;
		color: $darkContrastBlack;
	}
}
