@import "../common/variables";

.actions {
  text-align: end;
  display: flex;

  .button {
    padding: 0;

    &-red {
      color: $red;
    }
    &-green {
      color: $green;
    }
    &-blue {
      color: $blue;
    }
    &-orange {
      color: $orange;
    }
  }
}
