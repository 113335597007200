.channel-passes-table tr.MuiTableRow-root,
table thead .MuiTableRow-root.MuiTableRow-head {
    height:3em;

    .id-cell{
        max-width:125px;
        white-space: nowrap;
    }
    .channel-pass-name-cell{
        min-width: 100px;
    }
    .created-cell, .rental-period-cell, .purchases-cell{
        max-width: 50px;
        white-space: nowrap;
    }

@media screen and (max-width: 60em) {
    td {
        display: block;
        width: auto;
        border-bottom: 0;
    }
    width: 95vw;
    display: grid;
    padding: 0.2em 0;
    border-bottom: 1px solid gray;
    grid-template-columns: 200px 200px auto 65px;
    grid-template-rows: auto;

    .id-cell, .created-cell, .purchases-cell {
        display: none;
    }
    .channel-pass-name-cell{
        white-space: wrap;
        max-width: 150px;
    }
}
}