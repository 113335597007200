.publishVideosModal {
	.publishVideosModal__videos {
		display: flex;
		flex-direction: column;
		max-height: 30rem;
		overflow-y: auto;
		padding: 1rem 2rem;
		flex-wrap: nowrap;
	}

	.MuiCheckbox-colorSecondary.Mui-checked {
		color: $green;
	}

	.MuiFormControlLabel-root:hover {
		background-color: rgba(128, 128, 128, 0.2);
	}
}
