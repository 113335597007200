.title {
	text-align: left;
	padding: 15px 0;
	font-weight: bold;
	font-size: 26px;
}

.subtitle {
	text-align: left;
	padding-bottom: 16px;
}
