.statsTable {
	padding: 2rem 2rem 3rem 2rem;
	overflow: auto;
	min-height: 16rem;
	position: relative;

	&__name {
		font-size: 1.6rem;
		font-weight: bold;
	}

	&__header {
		display: table-row !important;
	}

	&__spinner {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		margin-top: 2rem;
	}
}
