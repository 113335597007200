.MuiContainer-root.usersScreen .verified-tab {

  .row:not(.status_LIVE) td {
    color: #aaa;
    text-decoration: line-through;
  }

  @media (max-width: 40em) {
    padding: none;
    .MuiTableRow-root {
        display: grid;
        grid-template-areas: 
          'uid email referrer actions'
          'roles signedup referrer actions'
        ;
        grid-template-columns: 50px auto 70px 80px;
        border-bottom: 1px solid lightgray;
        margin: 4px 0;
        height: auto;
        // grid-auto-rows: minmax(0px, auto);
      >td, >th {
        width: fit-content;
        border: none;
      }
      >:first-child { grid-area: uid; }
      >:nth-child(2) { grid-area: email; max-width: 45vw;  }
      >:nth-child(3) { grid-area: roles;  }
      >:nth-child(4) { grid-area: referrer; }
      >:nth-child(5) { grid-area: signedup; }
      >:nth-child(6) { grid-area: actions; }
    }
  }
  .MuiTableCell-root.image-cell {
    padding-right: 0;
    max-width: fit-content;
    width: fit-content;
  }

  .image {
    width: 48px;
    height: 48px;
    margin: 5px 0 2px 0;
  }

  .nameContainer {
    .name {
      font-weight: bold;
    }
  }

  .email {
    cursor: pointer;
  }

  .paginationContainer {
    padding: 10px 0;
  }
}
