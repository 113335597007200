.stream-keys-table tr.MuiTableRow-root, table thead .MuiTableRow-root.MuiTableRow-head {
    height: 3em;

    .videos-cell span {
		width: 70px;
        cursor: pointer;
	}

    .videos-cell span:hover {
        text-decoration: underline;
    }

    @media screen and (max-width: 40em) {
        td { display: block; width: auto; border-bottom: 0; }
        width: 95vw;
        height: auto;
        display: grid;
        padding: 0.2em 0;
        border-bottom: 1px solid gray;
        grid-template-columns: 80px auto 65px;
        grid-template-rows: auto;

        .id-cell { display:none }
        .stream-key-name-cell { grid-area:auto;  width: fit-content; }
        .key-cell { grid-area: auto; width: fit-content; }
        .videos-cell { grid-area: auto; width: fit-content; }
        .copy-rtmp-cell { display:none}
    }}
