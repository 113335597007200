.filterDate {
  padding: 1rem;

  .formControl {
    margin: 8px;
    min-width: 220px;
    max-width: 400px;
    text-align: left;
  }
  .dateControl {
    margin: 8px 8px 0;
    min-width: 220px;
    max-width: 400px;
    text-align: left;
  }
  .chips {
    display: flex;
    flex-wrap: wrap;
  }
  .chip {
    margin: 2px;
  }
  .select {
    max-width: 200px;
  }
  .menuItem {
    background: red;
  }
}
