.form {
  font-size: 14px;

  & &--container {
    margin-bottom: 10px;
    width: 100%;
  }

  & &--title {
    font-weight: bold;
  }

  & &--info {
    padding: 10px 0;
  }

  & &--textField {
    height: 70px;
    padding: 10px 0;
    width: 100%;
  }

  & &--imageButton {
    font-size: 14px;
  }
}
