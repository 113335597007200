.imageUpload {
  &--profile {
    border: 2px dashed rgba(255, 255, 255, 0.5);
    border-radius: 50%;
  }

  &__content {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    border-radius: inherit;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .profileImage {
    border-radius: 50%;
    background: none;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9999;
  }

  input[type='file'] {
    display: none;
  }
}
