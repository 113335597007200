.backgroundStyleInput {
	padding: 4px;
	display: flex;
	flex-direction: column;
	row-gap: 10px;
	border: solid 1px gray;
	border-radius: 4px;

	&--preview {
		height: 80px;
	}

	&--gradientPoint {
		display: flex;
		gap: 5px;
		align-items: center;
	}
}
