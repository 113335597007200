@import "../common/variables";

.menu-top {
  .root {
    flex-grow: 1
  }

  .logout {
    text-transform: capitalize;
    background-color: white;
    color: black;
    border-radius: 0;
    font-weight: bold;
    padding: 4px 20px;
    margin-left: auto;
    &:hover {
      background: $primary;
    }
  }

  .link {
    cursor: pointer;
    margin: 0 2vw;
    border-style: solid;
    border-width: 2px;
    border-color: transparent;
    text-decoration: none;
    color: white;
    font-weight: bold;
    &:hover {
      text-decoration: none;
      color: $primary;
      border-bottom-color: $primary;
    }
  }

  .activeLink {
    text-decoration: none;
    color: $primary;
    border-bottom-color: $primary;
  }
}
