.panel {
  .description {
    color: #8e8c8c;
    padding: 10px;
  }
  .value-panel {
    font-size: 30px;
    font-weight: 400;
  }

	.details-label {
		max-width: 261px;
		margin: 8px auto;
		text-align: start;
		font-size: 10px;
		color: $recastDisabled;
	}

	.details {
		max-width: 261px;
		margin: 8px auto;
		display: flex;
		flex-direction: column;
		gap: 9px;
	}

	.details-item {
		display: flex;
		font-size: 12px;

		&-name {
			color: $recastDisabled;
		}

		&-spacer {
			flex-grow: 1;
			border-bottom: #979797 dotted 1px;
			margin: 2px;
		}
	}
}
