.filter {
  text-align: left;
  margin: 1em 0;

  .label {
    font-weight: bold;
    padding-right: 1em;
  }

  & .radioGroup {
    display: inline-block;
  }

  .button {
    cursor: pointer;
    color: dodgerblue;
    &:hover {
      text-decoration: underline;
    }
  }
}
