.channelWidgetScreen {
	.MuiTableRow-root{
		height:3em;
		
	}
	.widgets-table .name-cell.header {
		width: 80%; 
		
	}
	
	.widgets-table .actions-cell.header {
		width: 20%; 
	
	}
	
}

.channelWidgetModal {
	.MuiPaper-root {
		padding-top: 64px;
		padding-bottom: 64px;
		padding-left: 36px;
		padding-right: 36px;
		max-width: 700px;
	}

	.MuiDialogTitle-root {
		padding-left: 0px;
		padding-right: 0px;
		padding-bottom: 4px;
		padding-top: 0px;
	}

	.MuiDialogContent-root {
		padding: 20px 0px 24px 0px;

		.MuiInputBase-root {
			min-width: 532px;
		}
	}

	.MuiDialogActions-root {
		padding-left: 0px;
		padding-right: 0px;

		.MuiButton-contained.Mui-disabled {
			background-color: white;
		}
	}
	

	.helpText {
		color: $grayText;
		font-weight: 400;
		margin-bottom: 12px;
		font-size: 14px;
	}


	.widget-buttons{
		    padding:19px 40.5px 0px 0px;
		}
}
