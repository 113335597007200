.table {
  & .header {
    font-weight: bold;
    font-size: 14px;

    .input {
      font-size: 16px;
    }
  }

  & tr {
    cursor: pointer;
    height: 3em;

    &:hover {
      background: lightgray;
    }
  }
}
