.filterTitles {
  padding: 1rem;
  display: flex;

  &__form-control {
    min-width: 220px;
    max-width: 400px;

    .MuiTypography-root {
      font-weight: 700;
    }
    .MuiInputLabel-root {
      top: 10px;
      left: -14px;
    }
  }

  .MuiInput-input {
    font-weight: 700;
  }
}
