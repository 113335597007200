.contributorsDialog {
  @extend .dialog;

  .MuiDialog-paperWidthSm {
    min-width: 560px;
    height: calc(100% - 64px);
  }

  & .dialogActions {
    margin: 10px;
  }
}
