.channelsScreen {
    table.MuiTable-root {
        max-width: 65em;
    }
    tbody .MuiTableRow-root.row {
        .publisher-email { font-size: 0.9em; }
    }
    @media screen and (max-width: 55em) {
        .MuiTableRow-root.row, table thead .MuiTableRow-root.MuiTableRow-head {
            width: 95vw;
            height: auto;
            display: grid;
            padding: 0.2em 0;
            border-bottom: 1px solid gray;

            td { display: block; width: auto; border-bottom: 0; }
            .name-cell { grid-area: name; font-size: 1em; }
            .id-cell { grid-area: id; font-size: 1em; }
            .status-cell { grid-area: status; }
            .followers-cell { grid-area: followers; }
            .publisher-cell { 
                grid-area: publisher;
                div { display: inline-block; }
                div:first-child { margin-right: 0.3em; }
            }
            .created-cell { grid-area: created; }
            .actions-cell { grid-area: actions; }

            grid-template-columns: 80px 280px 100px 130px 50px 90px auto 120px;
            grid-template-rows: auto;
            grid-template-areas: 
                "id name created status actions" 
                "followers publisher created status actions"
            ;

            @media screen and (max-width: 40em) {
                grid-template-columns: 80px 240px 100px 130px;
                grid-template-areas: 
                    "id name status actions" 
                    "followers created status actions"
                    "publisher publisher publisher publisher"
                ;
                .MuiChip-root { font-size: 0.9em; height: 26px; }
                .MuiChip-label { padding-left: 6px; padding-right: 6px; }
                .status-cell { padding: 0; }
            }
    
            @media screen and (max-width: 30em) {
                grid-template-columns: 60px 180px 70px 130px;
                .publisher-cell div { font-size: 0.8em; }
                .name-cell { font-size: 0.9em; }
                .id-cell { font-size: 0.9em; }
            }
        }
    }
}