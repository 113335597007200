.videoDetailsScreen {
  & .section {
    padding: 20px 0;
    text-align: left;
  }

  & .sectionHeader {
    font-size: 20px;
    font-weight: bold;
  }

  & .sectionTitle {
    display: inline-block;
    margin: 12px 0;
  }

  & .sectionRow {
    margin: 10px 0;
  }

  & .sectionButtons {
    margin: 20px 0 10px 0;
    display: flex;
    justify-content: flex-end;
  }

  & .videoDetailsActions {
    padding-bottom: 15px;
  }

  & .statsSection {
	overflow: auto;
	margin-bottom: 10px;
  }

  & .purchasesSection {
	float: left;
	width: 50%;
  }

  & .refundsSection {
	float: right;
	width: 50%;
  }
}
