td.MuiTableCell-sizeSmall.MuiTableCell-paddingCheckbox {
    &.sales-cell, &.cost-cell, &.spend-cell { 
        text-align: right; 
        padding-right: 20px; 
        padding-left: 0;
    }
}

@media screen and (max-width: 60em) {
    table tbody .MuiTableRow-root.video-row.row {
        .name-cell { font-size: 0.9em; }
    }
    table .MuiTableRow-root.video-row.row, table thead tr.MuiTableRow-root.MuiTableRow-head {
        td { display: block; width: auto; border-bottom: 0; }
        width: 95vw;
        height: auto;
        display: grid;
        padding: 0.2em 0;
        border-bottom: 1px solid gray;

        grid-template-columns: 90px 50px 50px 50px 50px 90px auto 120px;
        grid-template-rows: auto;
        grid-template-areas: 
            "image title title title title title title publisher publisher publisher" 
            "image price live published featured created actions publisher publisher publisher"
            "image price live published featured created actions uid sales spend";

        @media screen and (max-width: 40em) {
            grid-template-columns: 90px 50px 50px 50px 50px 90px auto;
            grid-template-rows: auto;
            grid-template-areas: 
                "image title title title title title title" 
                "image publisher publisher publisher publisher publisher" 
                "image price live published featured created actions uid";
            .uid-cell { text-align: right; }
            .sales-cell, .spend-cell { display: none;}
        }

        @media screen and (max-width: 32em) {
            grid-template-columns: 90px 50px 50px 50px 50px auto;
            grid-template-rows: auto;
            grid-template-areas: 
                "image title title title title title title" 
                "image publisher publisher publisher publisher publisher publisher" 
                "image price live published featured actions actions"
                "uid created created created created sales spend" 
               ;
            .uid-cell { text-align: center; }
            .sales-cell, .spend-cell { display: block;}
        }
        .name-cell { grid-area: title; padding-bottom: 0.2em; }
        .image-cell { grid-area: image; }
        .cost-cell { grid-area: price; }
        .publisher-cell { grid-area: publisher; }
        .live-cell { grid-area: live; }
        .published-cell { grid-area: published; }
        .featured-cell { grid-area: featured; }
        .created-cell { grid-area: created; }
        .actions-cell { grid-area: actions; }
        .uid-cell { grid-area: uid; }
        .sales-cell { grid-area: sales; }
        .spend-cell { grid-area: spend; }
    }
    table tbody .MuiTableRow-root.video-row.row {
        .name-cell { font-size: 0.9em; }
        .cost-cell { font-size: 1.2em; }
        .cost-cell::after { content: "C"; font-size: 0.7em; }
    }
}
