.pagination {
  display: inline-block;

  & .overview {
    vertical-align: middle;
  }

  & .numbers {
    display: inline-block;
  }
}
