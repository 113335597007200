@import "../common/variables";

.videosTable {
  .image {
    width: 80px;
    height: 45px;
  }

  & .yellow {
    background: #fcf8e3;

    &:hover {
      background: #fff1a6;
    }
  }

  & .red {
    background: #fdecea;

    &:hover {
      background: #ffd8d4;
    }
  }

  & .true {
    color: $green;
  }

  & .false {
    color: $red;
  }

  & .grey {
    background: #c8c8c8;
  }

  .pagination {
    display: inline-block;
  }

  .paginationContainer {
    display: flex;
    padding: 10px 0;
  }
}
