.refundsModal {
    &-title {
        color: #AD130E;
        font-size: 2.2rem;
        .MuiTypography-root {
            font-weight: bold;
            line-height: 30px;

        }
    }
    &-errors {
        color: #AD130E;
    }
	&-reason.MuiFormControl-root {
		margin-bottom: 12px;
	}
	&-confirmRefund.MuiButtonBase-root {
		background: #AD130E;
		color: white;
		font-weight: bold;
		&:hover {
			background-color: #D32F2F;
		}
	}
	&-confirmRefund.MuiButtonBase-root.Mui-disabled {
		background-color: white;
		font-weight: bold;
	}
	&-cancelRefund.MuiButtonBase-root {
		background: white;
		font-weight: bold;
		margin-right: 15px;
	}
}
