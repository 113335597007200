.userDetailsScreen {
  @extend .videosTable;

  & .section {
    padding: 20px 0;
    text-align: left;
    
    &--video {
      & .section {
        padding-left: 16px;
      }
    }

    &--disabled {
      color: rgba(0, 0, 0, 0.6);
    }
  }

  & .sectionHeader {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
  }

  & .sectionTitle {
    display: inline-block;
    margin: 12px 0;
  }

  & .sectionParagraph {
    font-size: 14px;
    line-height: 18px;
    color: rgb(105, 105, 105);
  }

  & .profileImage {
    width: 72px;
    height: 72px;
  }

  & .bannerImage {
    width: 300px;
    height: 100px;
  }

  & .userDetailsActions {
    padding-bottom: 15px;
  }

  & .sectionRow {
    margin: 10px 0;
  }
}
