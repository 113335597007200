.countryAvailabilityDialog {
  @extend .dialog;

  & .button-availability {
    width: 100%;
  }
  & .expansionPanelDetails {
    margin: 5px 10px;
    width: 100%;
  }
  .scroll {
    overflow-y: scroll;
  }

  .MuiIconButton-sizeSmall {
    background-color: #e0e0e0;
    &:hover {
      background-color: #d5d5d5;
    }
  }
}
