.loginScreen {
  .paper {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .avatar {
    margin: 1px;
    background-color: #000;
  }

  .form {
    width: 100%;
    margin-top: 1px;
    color: black;
  }

  .field {
    &:hover {
      border-color: #000;
    }
  }

  .submit {
    margin: 3px 0 0 3px;
  }
}
