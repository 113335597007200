
$primary: #fed200;

$red: #d9534f;
$green: #5cb85c;
$blue: dodgerblue;
$orange: #f0ad4e;

$grayText: #666666;
$disabledFillGray: #F3F3F3;
$borderGray: #D9D9D9;
$divider: #E0E0E0;

$recastBlack: #161616;
$popupTextGray: #696969;
$recastDisabled: #8d8d8d;
$darkContrastBlack: rgba(0,0,0,0.87);
