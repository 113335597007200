.admin-table tr.MuiTableRow-root, table thead .MuiTableRow-root.MuiTableRow-head {
    height: 3em;

	.fans-data-cell {
		width: 70px;
	}

    @media screen and (max-width: 40em) {
        td { display: block; width: auto; border-bottom: 0; }
        width: 95vw;
        height: auto;
        display: grid;
        padding: 0.2em 0;
        border-bottom: 1px solid gray;

        grid-template-columns: 80px auto 65px;
        grid-template-rows: auto;
        grid-template-areas: 
            "uid username actions" 
            "uid email actions";

        .uid-cell { grid-area: uid; }
        .username-cell { grid-area: username;  width: fit-content; }
        .email-cell { grid-area: email; width: fit-content; }
		.fans-data-cell { grid-area: fans-data; width: fit-content; }
        .actions-cell { grid-area: actions; }
    }
}
