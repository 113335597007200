@import 'common/variables';
@import 'common/grid';
@import 'common/fonts';
@import 'components/menubar';
@import 'components/dialog';
@import 'components/filter';
@import 'components/filterGroup';
@import 'components/title';
@import 'components/table';
@import 'components/actions';
@import 'components/form';
@import 'components/imageInput';
@import 'components/multiselect';
@import 'components/pagination';
@import 'components/tabs';
@import 'components/panel';
@import 'components/paper';
@import 'components/filterDate';
@import 'components/filterTitles';
@import 'components/categoriesTree';
@import 'components/backgroundStyleInput';
@import 'components/publishVideosModal';
@import 'components/manualCastModal';
@import 'components/statsTable';
@import 'components/purchases';
@import 'components/refundsModal';

@import 'dialog/userVideosDialog';
@import 'dialog/countryAvailabilityDialog';
@import 'dialog/inviteUserDialog';
@import 'dialog/editUserDialog';
@import 'dialog/confirmationDialog';
@import 'dialog/contributorsDialog';
@import 'dialog/editSplitEarningsDialog';
@import 'dialog/editAdminDialog';

@import 'screens/login';
@import 'screens/imageUpload';
@import 'screens/cropperImage';
@import 'screens/video';
@import 'screens/userDetails';
@import 'screens/videoDetails';
@import 'screens/channelConfig';
@import 'screens/channelWidget';

@import 'table/videos';
@import 'table/walletTransactions';

.App {
    .MuiTableCell-sizeSmall.MuiTableCell-paddingCheckbox {
        padding: 0 5px;
        padding-left: 5px;
        padding-right: 5px;
    }
    .MuiTableCell-sizeSmall.MuiTableCell-paddingCheckbox:last-child {
        padding-left: 5px;
        padding-right: 0;
    }
    .MuiTabPanel-root {
        padding: 24px 1vw;
    }
    .MuiContainer-root {
        padding-left: 1vw;
        padding-right: 1vw;
    }
}
