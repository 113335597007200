.croppingModal {

	.CropperImage img {
		width: 100%;
		object-fit: contain;
	}

	.CropperImage {
		position: center;
		margin: 1rem;
		object-fit: contain;

		.cropper-view-box {
			box-shadow: 0 0 0 2px white;
			outline: inherit;
		}

		.cropper-point {
			background-color: white;
			width: 24px;
			height: 24px;
		}

		.cropper-point.point-s {
			bottom: -12px;
			margin-left: -12px;
		}

		.cropper-point.point-w {
			left: -12px;
			margin-top: -12px;
		}

		.cropper-point.point-n {
			margin-left: -12px;
			top: -12px;
		}

		.cropper-point.point-e {
			margin-top: -12px;
			right: -12px;
		}

		.cropper-face {
			background-color: inherit;
		}

		.cropper-dashed,
		.cropper-point.point-se,
		.cropper-point.point-sw,
		.cropper-point.point-nw,
		.cropper-point.point-ne,
		.cropper-line {
			display: none;
		}
	}

	.CropperButton {
		text-align: center;

		.button {
			padding: 0.5rem 5rem;
		}
	}

	.CropperImage-profile {
		.cropper-view-box {
			border-radius: 50%;
			box-shadow: 0 0 0 2px white;
			outline: inherit;
		}
	}
}
