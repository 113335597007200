.editSplitEarningsDialog {
  @extend .dialog;

  .MuiDialog-paper {
    min-width: 500px;
  }

  .formField {
    margin: 0 10px;
  }

  .MuiDialogActions-root {
    margin: 0 15px;
  }
}
