.treeNode {
	display: flex;
	align-items: center;
	gap: 15px;
}

.categoriesActions {
	padding-inline-start: 10px;
}
.categoriesSearch {
	margin-bottom: 20px!important;
	width: 300px;
	.MuiInputBase-input {
		padding: 12px;
	}
}

.categoriesFilters {
	display: flex;
	justify-content: center;

	.filter {
		margin: 5px;
		font-weight: bold;
		background-color: #303030;
		color: #fff;
		text-transform: none;
		border-radius: 64px;
		padding: 10px 16px;
		line-height: 10px;

		&:hover {
			background-color: #606060;
			color: #fff;
		}

		&--selected {
			background-color: #fed200;
			color: #1a1a1a;
		}
	}
}

.categoriesTree {
	height: calc(100vw - 400px);
}
