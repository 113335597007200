.confirmationDialog {
  @extend .dialog;

  & .button-yes {
    width: 100px;
    margin: 10px;
    float: right;
  }
  & .button-cancel {
    width: 100px;
    margin: 10px;
    float: right;
  }
  & .dialogActions {
    margin: 10px;
  }
}
