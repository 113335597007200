.walletTransactions {
	&__title {
		font-weight: bold;
		padding-top: 1rem;
	}

	&__table {
		margin: 1rem auto 0 auto;
		border-collapse: collapse;

		thead {
			font-weight: bold;
			background-color: #ece9e9;
			padding: 1rem;
		}

		tr {
			height: 2.6rem;
			border-color: gray;
			border-bottom: 1px solid #c5c5c5;
		}

		tr {
			text-align: left;
		}
	}

	.refund {
		&-active {
			.MuiSvgIcon-root{
				fill: #3233FF;
			}
		}
	}
}
