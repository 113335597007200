@import '../common/variables';

.editAdminDialog {
	& .title {
		font-size: 22px;
		font-weight: bold;
		line-height: 30px;
		color: $recastBlack;
	}

	& .title-disabled {
		font-size: 22px;
		font-weight: bold;
		line-height: 30px;
		color: $recastDisabled;
	}

	& .description {
		font-size: 12px;
		font-weight: 400;
		line-height: 20px;
		color: $popupTextGray;
	}

	& .description-highlighted {
		font-size: 12px;
		font-weight: bold;
		line-height: 20px;
		color: $recastBlack;
	}

	& .description-highlighted-disabled {
		font-size: 12px;
		font-weight: bold;
		line-height: 20px;
		color: $recastDisabled;
	}

	& .toggleButton {
		font-size: 14px;
		font-weight: 400;
	}

	& .button {
		font-size: 14px;
		font-weight: bold;
	}
}
