.dialog {
	& .formField {
		margin: 10px 0;
		height: 60px;
	}

	& .formError {
		color: red;
	}

	& .code {
		font-family: monospace;
	}

	& .numericField {
		margin: 10px 0;
		height: 72px;
		width: 320px;
	}

	& .warning {
		width: auto;
	}

	& .expansionPanel {
		margin: 5px 5px;
		height: 100%;
	}
}
