@font-face {
	font-family: 'Poppins';
	src: url('../../fonts/Poppins-Light.woff2') format('woff2');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Poppins';
	src: url('../../fonts/Poppins-Italic.woff2') format('woff2');
	font-weight: 400;
	font-style: italic;
}


@font-face {
	font-family: 'Poppins';
	src: url('../../fonts/Poppins-Medium.woff2') format('woff2');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Poppins';
	src: url('../../fonts/Poppins-MediumItalic.woff2') format('woff2');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Poppins';
	src: url('../../fonts/Poppins-SemiBold.woff2') format('woff2');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Poppins';
	src: url('../../fonts/Poppins-SemiBoldItalic.woff2') format('woff2');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Poppins';
	src: url('../../fonts/Poppins-Bold.woff2') format('woff2');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Poppins';
	src: url('../../fonts/Poppins-BoldItalic.woff2') format('woff2');
	font-weight: 700;
	font-style: italic;
}
